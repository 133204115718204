html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  user-select: none;
  font-family: IM_Hyemin-Regular;
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
}

button {
  border: none;
  cursor: pointer;
}


@font-face {
  font-family: 'FOUREYES';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/FOUREYES.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IM_Hyemin-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2106@1.1/IM_Hyemin-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}